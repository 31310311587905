import axios from 'axios';

export const inrToUsd =
	'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/inr.json';

export const getUSDPrice = async () => {
	try {
		const response = await axios.get(inrToUsd);

		return response.data.inr.usd;
	} catch (error) {
		console.log(error);
		return 0;
	}
};

export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};
