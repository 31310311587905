import { toast } from 'react-toastify';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { JingleDeliveries, jingleLengths, customizedSong } from 'config/orders';
import { ReactComponent as MusicIcon } from 'assets/icons/music.svg';
import classNames from 'classnames';
import classes from './Content.module.scss';
import { useEffect, useState } from 'react';
import { getUSDPrice } from 'utils';
import { useSearchParams } from 'react-router-dom';

const MAX_CHAR_LEN_FOR_JINGLE = 200;
const MAX_CHAR_LEN_FOR_JINGLE_TWO_MINUTE = 400;

interface Step2Props {
	onNext: () => void;
	onBack: () => void;
	details: any;
	notes: any;
	user: any;
	from: any;
	to: any;
	ocassion: any;
	relation: any;
	phoneNumber: any;
	coupon: any;
	delivery: any;
	prices: any;
	mood: any;
	state: any;
	length: any;
	lengthKey: any;
}

export default function Step2(props: Step2Props) {
	const {
		details,
		notes,
		user,
		phoneNumber,
		delivery,
		prices,
		mood,
		from,
		to,
		relation,
		ocassion,
		onNext,
		state,
		length,
		lengthKey,
	} = props;

	//newly added

	const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
		''
	);
	const [selectedState, setSelectedState] = useState<string | undefined>('');

	const countries: string[] = ['India', 'USA', 'OTHERS']; // Add more countries if needed

	const statesByCountry: { [key: string]: string[] } = {
		India: [
			'Andhra Pradesh',
			'Arunachal Pradesh',
			'Assam',
			'Bihar',
			'Chhattisgarh',
			'Goa',
			'Gujarat',
			'Haryana',
			'Himachal Pradesh',
			'Jharkhand',
			'Karnataka',
			'Kerala',
			'Madhya Pradesh',
			'Maharashtra',
			'Manipur',
			'Meghalaya',
			'Mizoram',
			'Nagaland',
			'Odisha',
			'Punjab',
			'Rajasthan',
			'Sikkim',
			'Tamil Nadu',
			'Telangana',
			'Tripura',
			'Uttar Pradesh',
			'Uttarakhand',
			'West Bengal',
			'Andaman and Nicobar Islands',
			'Chandigarh',
			'Dadra and Nagar Haveli and Daman and Diu',
			'Lakshadweep',
			'Delhi',
			'Puducherry',
		],
		USA: [
			'Alabama',
			'Alaska',
			'Arizona',
			'Arkansas',
			'California',
			'Colorado',
			'Connecticut',
			'Delaware',
			'Florida',
			'Georgia',
			'Hawaii',
			'Idaho',
			'Illinois',
			'Indiana',
			'Iowa',
			'Kansas',
			'Kentucky',
			'Louisiana',
			'Maine',
			'Maryland',
			'Massachusetts',
			'Michigan',
			'Minnesota',
			'Mississippi',
			'Missouri',
			'Montana',
			'Nebraska',
			'Nevada',
			'New Hampshire',
			'New Jersey',
			'New Mexico',
			'New York',
			'North Carolina',
			'North Dakota',
			'Ohio',
			'Oklahoma',
			'Oregon',
			'Pennsylvania',
			'Rhode Island',
			'South Carolina',
			'South Dakota',
			'Tennessee',
			'Texas',
			'Utah',
			'Vermont',
			'Virginia',
			'Washington',
			'West Virginia',
			'Wisconsin',
			'Wyoming',
		],
		OTHERS: [
			'Ontario',
			'Quebec',
			'British Columbia',
			'Alberta',
			'Manitoba',
			'Saskatchewan',
			'New South Wales',
			'Victoria',
			'Queensland',
			'Western Australia',
			'South Australia',
			'Tasmania',
			'England',
			'Scotland',
			'Wales',
			'Northern Ireland',
			'Bavaria',
			'North Rhine-Westphalia',
			'Baden-Württemberg',
			'Lower Saxony',
			'Hesse',
			'Saxony',
			'Île-de-France',
			'Provence-Alpes-Côte d Azur',
			'Auvergne-Rhône-Alpes',
			'Occitanie',
			'Hauts-de-France',
			'Brittany',
			'São Paulo',
			'Minas Gerais',
			'Rio de Janeiro',
			'Bahia',
			'Rio Grande do Sul',
			'Paraná',
			'Guangdong',
			'Shandong',
			'Henan',
			'Sichuan',
			'Jiangsu',
			'Hebei',
			'Tokyo',
			'Osaka',
			'Hokkaido',
			'Aichi',
			'Kanagawa',
			'Fukuoka',
		],
		// Add more countries and states if needed
	};

	const [usdPrice, setUsdPrice] = useState(0);

	const [searchParams] = useSearchParams();
	const plan = searchParams.get('plan');

	const callPrice = async () => {
		const price = await getUSDPrice();
		setUsdPrice(price);
	};

	useEffect(() => {
		callPrice();
	}, []);

	return (
		<div className={classes.formContainer}>
			<div className={classes.mood}>
				<div className={classes.question}>
					<h3>How about the mood?</h3>
					<p className={classes.subtext}>
						This gives the artist some extra inspiration for your song, to
						better fit your story.
					</p>
				</div>
				<div className={classes.options}>
					{customizedSong.map((option) => (
						<div
							key={option.value}
							className={classNames(classes.option, {
								[classes.selected]: mood.value === option.value,
							})}
							onClick={() => mood.set(option.value)}
						>
							<label htmlFor={option.value}>{option.label}</label>
							<input
								type="radio"
								name="mood"
								id={option.value}
								value={option.value}
								checked={mood.value === option.value}
								onChange={mood.onChange}
							/>
						</div>
					))}
				</div>
			</div>

			{!plan || (plan !== 'pro' && plan !== 'premium') ? (
				<div className={classes.songlength}>
					<div className={classes.question}>
						<h3>How long of a song?</h3>
						<p className={classes.subtext}>All rates are inclusive of GST</p>
					</div>
					<div className={classes.options}>
						{jingleLengths.map((option) => (
							<div
								key={option.key}
								onClick={() => {
									lengthKey.set(option.key);
									length.set(option.value);
								}}
								className={classNames(classes.option, {
									[classes.selected]: length.value === option.value,
								})}
							>
								<label htmlFor={option.value}>
									<span>
										{option.label}{' '}
										{option.label === '1'
											? 'minute'
											: option.label === '30'
											? 'seconds'
											: 'minutes'}
									</span>
									<div className={classes.prices}>
										<span className={classes.price}>
											₹ {prices?.actual[option.key]} ($
											{(prices?.actual[option.key] * usdPrice).toFixed(2)})
										</span>
									</div>
								</label>
								<input
									type="radio"
									id={option.value}
									value={option.value}
									checked={length.value === option.value}
									onChange={length.onChange}
								/>
								<MusicIcon />
							</div>
						))}
					</div>
				</div>
			) : null}

			<div className={classes.details}>
				<div className={classes.question}>
					<h3 style={{ marginBottom: 10 }}>Who is the song from:</h3>
				</div>

				<div className={classes.form}>
					<div className={classes.inputs}>
						<input
							placeholder="What's your email?"
							value={user.value}
							onChange={user.onChange}
						/>
						<input
							placeholder="What's your phone number?"
							value={phoneNumber.value}
							onChange={phoneNumber.onChange}
						/>
					</div>
					<div className={classes.inputs}>
						<input
							placeholder="Who is gifting the song? Ex. Dhruv"
							value={from.value}
							onChange={from.onChange}
						/>
						<input
							placeholder="Who is the song for? Ex. Ananya"
							value={to.value}
							onChange={to.onChange}
						/>
					</div>
					<div className={classes.inputs}>
						<input
							placeholder="What's your relation? Ex. Husband, Wife, Boyfriend, Friend, Parents. etc"
							value={relation.value}
							onChange={relation.onChange}
						/>
					</div>
					<input
						//  className={classes.myplaceholder}
						placeholder="What's the occasion? Ex. Birthday, Anniversary, Wedding, Proposal or any Occation"
						value={ocassion.value}
						onChange={ocassion.onChange}
					/>
					{/* <input
						placeholder="Which State? (Ex. Delhi, UP, etc. )"
						value={state.value}
						onChange={state.onChange}
					/> */}
					{/* <div className={classes.stateDropdown}>
						<label className={classes.stateDropdownlabel}>Select State:</label>
						<select
							placeholder="Which State? (Ex. Delhi, UP, etc. )"
							value={state.value}
							onChange={state.onChange}
						>
							<option value="" disabled>
								Select a state
							</option>
							{indianStates.map((stateName) => (
								<option key={stateName} value={stateName}>
									{stateName}
								</option>
							))}
						
						</select>
					</div> */}

					<div className={classes.stateDropdown}>
						<label className={classes.stateDropdownlabel1}>
							Select Location:
						</label>

						<select
							className={classes.stateDropdownlabel}
							placeholder="Select Country"
							value={selectedCountry || ''}
							onChange={(e) => setSelectedCountry(e.target.value)}
						>
							<option value="" disabled>
								Select a country
							</option>
							{countries.map((country) => (
								<option key={country} value={country}>
									{country}
								</option>
							))}
						</select>
						<select
							className={classes.stateDropdownlabel}
							placeholder="Select State"
							value={state.value}
							onChange={state.onChange}
						>
							<option value="" disabled>
								Select a state
							</option>
							{selectedCountry &&
								statesByCountry[selectedCountry].map((state) => (
									<option key={state} value={state}>
										{state}
									</option>
								))}
						</select>
					</div>
				</div>

				<div>
					<div className={`${classes.inputs} ${classes.textareaInput}`}>
						<textarea
							placeholder="Write your Message in Hindi or English ... e.g. Hey Vrinda, I can’t forget our college trip to Manali where we first met. 
							I love you, wishing you a very happy birthday. "
							value={notes.value}
							onChange={notes.onChange}
							maxLength={
								lengthKey.value === 'jingle'
									? MAX_CHAR_LEN_FOR_JINGLE
									: MAX_CHAR_LEN_FOR_JINGLE_TWO_MINUTE
							}
						/>
						<span>
							{notes.value?.length}/
							{lengthKey.value === 'jingle'
								? MAX_CHAR_LEN_FOR_JINGLE
								: MAX_CHAR_LEN_FOR_JINGLE_TWO_MINUTE}
						</span>
					</div>
				</div>
			</div>
			<div className={classes.question}>
				<h3 style={{ marginBottom: 10 }}>
					We Will deliver your custom song in 3-5 days on your email!{' '}
				</h3>
			</div>
			<hr />
			<div className={classes.footer}>
				<button className={classes.moveNextButton} onClick={props.onBack}>
					Back
				</button>
				<button className={classes.moveNextButton} onClick={onNext}>
					Next
				</button>
			</div>
		</div>
	);
}
