import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsMusicNoteBeamed, BsPlayCircle, BsPauseCircle } from 'react-icons/bs';
import classes from './PricingCard.module.scss';
import { PricingCardData } from '../pricingCardData';

const PricingCard: React.FC<{ pricingData: PricingCardData }> = ({
	pricingData,
}) => {
	const navigate = useNavigate();
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const audioRef = React.useRef(new Audio(pricingData.audioUrl));

	const togglePlay = () => {
		const audio = audioRef.current;
		if (isPlaying) {
			audio.pause();
		} else {
			audio.play();
		}
		setIsPlaying(!isPlaying);
	};

	const handleTimeUpdate = () => {
		setCurrentTime(audioRef.current?.currentTime || 0);
	};

	const handleScrub = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newTime = parseFloat(event.target.value);
		audioRef.current.currentTime = newTime;
		setCurrentTime(newTime);
	};

	return (
		<div className={classes.card}>
			{pricingData.recommended ? (
				<div className={classes.recommended}>Recommended</div>
			) : null}
			{pricingData.mostrecommended ? (
				<div className={classes.mostrecommended}>Highly Recommended</div>
			) : null}
			<h3 className={classes.heading}>{pricingData.planName}</h3>
			<ul className={classes.description}>
				{pricingData.description.map((desc, index) => (
					<li key={index} className={classes.description_point}>
						<BsMusicNoteBeamed className={classes.list_icon} />
						<span className={classes.list_text}>{desc}</span>
					</li>
				))}
			</ul>
			<p className={classes.price}>{pricingData.price}</p>

			<p className={classes.starts}>Starting From</p>

			<div className={classes.playContainer}></div>

			<div className={classes.playPauseHolder}>
				<button onClick={togglePlay} className={classes.buttonPlay}>
					{isPlaying ? (
						<BsPauseCircle className={classes.play_icon} />
					) : (
						<BsPlayCircle className={classes.play_icon} />
					)}
				</button>
				<input
					type="range"
					min={0}
					max={audioRef.current?.duration || 0}
					value={currentTime}
					onChange={handleScrub}
					className={classes.scrubber}
				/>
				<img
					className={classes.waveformImg}
					src="https://res.cloudinary.com/diuoze6fm/image/upload/v1710492521/melotales/360_F_327369570_CAxxxHHLvjk6IJ3wGi1kuW6WTtqjaMpc-removebg-preview_s9idqh.png"
					alt=""
				/>
			</div>

			<button
				onClick={() => navigate(pricingData.link)}
				className={classes.button}
			>
				Create My Song
			</button>

			<audio
				ref={audioRef}
				src={pricingData.audioUrl}
				onTimeUpdate={handleTimeUpdate}
				onEnded={() => setIsPlaying(false)}
			/>
		</div>
	);
};

export default PricingCard;
